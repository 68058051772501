import React, { Component } from "react";
import { FaInstagram, FaLinkedin, FaGithub } from "react-icons/fa";
import { BsMoon } from "react-icons/bs";

class Footer extends Component {
  render() {
    let moonStyle = { color: "white", fontSize: "1.5em", marginBottom: ".5em" };

    return (
      <div className="flex-container3">
        <div className="footerNetwork">
          <ul>
            <li>
              <a href="https://www.instagram.com/skyler_hogan">
                <i className="icon">
                  <FaInstagram></FaInstagram>
                </i>{" "}
              </a>
            </li>
            <li>
              <a href="https://github.com/skylerhogan">
                <i className="icon">
                  <FaGithub></FaGithub>
                </i>{" "}
              </a>
            </li>
            <li>
              <a href="https://www.linkedin.com/in/skylerhogan/">
                <i className="icon">
                  <FaLinkedin></FaLinkedin>
                </i>{" "}
              </a>
            </li>
          </ul>
        </div>
        <div className="copyright">
          built at <BsMoon style={moonStyle}></BsMoon> by <em>skyler</em>hogan
        </div>
      </div>
    );
  }
}

export default Footer;
