import React, { Component } from "react";
import { Navbar, Nav } from "react-bootstrap";
import Logo from "../images/logo/logo_lufga.png";
import Mini from "../images/logo/logo_mini_lufga.png";

class NavBar extends Component {
  render() {
    return (
      <div className="navbar">
        <Navbar
          variant="dark"
          fixed="top"
          expand="md"
          collapseOnSelect
          className="ml-auto"
        >
          <img src={Logo} alt=" " height="auto" className="logo"></img>{" "}
          <img src={Mini} alt=" " height="auto" className="mini"></img>{" "}
          <Navbar.Toggle className="ml-auto" id="hamburger" />
          <Navbar.Collapse className="ml-auto">
            <Nav className="ml-auto" id="nav-links">
              <Nav.Link href="#home">Home</Nav.Link>
              <Nav.Link href="#about">About</Nav.Link>
              <Nav.Link href="#tech">Tech</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    );
  }
}

export default NavBar;
