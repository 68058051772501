import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.css";
import { Container } from "react-bootstrap";
import { FaInstagram, FaLinkedin, FaGithub } from "react-icons/fa";
import Fade from "react-reveal/Fade";
import Zoom from "react-reveal/Fade";

class Header extends Component {
  render() {
    return (
      <div id="gradient">
        <Fade delay={250}>
          <header className="mydiv unselectable">
            <a id="home"></a>
            <Container>
              <div>
                <div>
                  <Zoom top delay={1000} duration={750}>
                    <h1 className="headline">
                      Hi<em>.</em>
                    </h1>
                  </Zoom>
                  {/* <Zoom bottom delay={2000} duration={750}>
                    <h1 className="name-headline">
                      I'm Skyler
                      <em>.</em>
                    </h1>
                  </Zoom> */}
                  <Fade delay={2000} duration={2000}>
                    <h3 className="name-h2">
                      I'm a <em>St. Louis</em>
                      <br /> based
                      <em> Software Engineering Student</em>
                      <br /> and
                      <em> Full Stack Developer</em>
                    </h3>
                  </Fade>
                </div>
                <div className="network">
                  <Fade bottom big cascade delay={1500} duration={3000}>
                    <ul className="headerNetwork">
                      <li>
                        <a href="https://www.instagram.com/skyler_hogan">
                          <i className="icon headerIcon">
                            <FaInstagram></FaInstagram>
                          </i>{" "}
                        </a>
                      </li>
                      <li>
                        <a href="https://github.com/skylerhogan">
                          <i className="icon headerIcon">
                            <FaGithub></FaGithub>
                          </i>{" "}
                        </a>
                      </li>
                      <li>
                        <a href="https://www.linkedin.com/in/skylerhogan/">
                          <i className="icon headerIcon">
                            <FaLinkedin></FaLinkedin>
                          </i>{" "}
                        </a>
                      </li>
                    </ul>
                  </Fade>
                </div>
              </div>
            </Container>
          </header>
        </Fade>
      </div>
    );
  }
}

export default Header;
