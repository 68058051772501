import React, { Component } from "react";
import Profile from "../images/profile(circle).png";
import {
  FaGrinBeam,
  FaMapMarkerAlt,
  FaEnvelope,
  FaPhone,
} from "react-icons/fa";
import Fade from "react-reveal/Fade";

class About extends Component {
  render() {
    return (
      <div className="mydiv unselectable">
        <a id="about"></a>
        <div className="about">
          <div className="flex-container">
            <div className="flex-left">
              <div className="photoAnimation">
                <Fade>
                  <img
                    className="profilePic"
                    src={Profile}
                    alt=" "
                    width="250vh"
                    height="250vw"
                  />{" "}
                </Fade>
              </div>
              <Fade left delay={250} duration={1000}>
                <div id="contact-info">
                  <div className="contact">
                    <FaGrinBeam className="aboutIcons" />
                    &nbsp;&nbsp;Skyler Hogan
                  </div>
                  <div className="contact">
                    <FaMapMarkerAlt className="aboutIcons" />
                    &nbsp;&nbsp;St. Louis, MO
                  </div>
                  <div className="contact" id="hyperlink">
                    <FaPhone className="aboutIcons" />
                    &nbsp;&nbsp;<a href="tel:+13147533020">314.753.3020</a>
                  </div>
                  <div className="contact" id="hyperlink">
                    <FaEnvelope className="aboutIcons" />
                    &nbsp;&nbsp;
                    <a href="mailto:skyler.hogan@gmail.com">
                      skylerhogan@gmail.com
                    </a>
                  </div>
                </div>
              </Fade>
            </div>
            <div className="flex-right">
              <Fade right delay={500} duration={1000}>
                <p id="about-top">
                  Building on a foundation of in-demand web technologies, I’m
                  actively seeking opportunities to continue to improve my
                  technical skills and gain a better grasp of problems that
                  inhibit people from understanding each other.
                </p>
                <p id="about-middle">
                  Through leveraging my background in customer-facing roles, I
                  aim to add value via my experience while working to solve
                  these problems with an emphasis on UI/UX and a commitment to
                  service.
                </p>
                <p id="about-bottom">
                  Outside of work I enjoy traveling, tinkering around on some
                  pet projects, or taking a deep-dive down a new rabbit-hole.
                </p>
              </Fade>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default About;
