import React, { Component } from "react";
import { Card } from "react-bootstrap";
import ReactPic from "../images/react.png";
import Bootstrap from "../images/bootstrap.png";
import Java from "../images/java.png";
import MySql from "../images/mysql.png";
import JS from "../images/javascript.png";
import Git from "../images/git.png";
import Adobe from "../images/adobe.png";
import Heroku from "../images/heroku.png";
import Fade from "react-reveal/Fade";
import Pulse from "react-reveal/Pulse";
import Slide from "react-reveal/Slide";

class Tech extends Component {
  render() {
    return (
      <div className="mydiv unselectable">
        <a id="tech"></a>
        <div className="tech">
          <div className="flex-container2">
            <Fade left>
              <div className="flex-left2">
                <em>FAVORITE TECH</em>
              </div>
            </Fade>
            <Fade duration={2000}>
              <Pulse delay={2000} duration={5000}>
                <div className="flex-right2">
                  Here's some of the tech I enjoy working with the most right
                  now.
                </div>
              </Pulse>
            </Fade>
          </div>

          <div className="tech-container">
            <Slide bottom duration={1500}>
              <Card
                id="card"
                className="border-0"
                style={{ width: "19em", borderWidth: "0" }}
              >
                <Card.Img variant="top" src={Git} id="cardImage" />
                <Card.Body>
                  <Card.Title id="cardTitle">GIT</Card.Title>
                  <Card.Text id="cardText">
                    From the beginning, I've used Git for version control on an
                    individual and team basis. As I continue to work on more
                    advanced projects its utility becomes all the more apparent.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Slide>
            <Slide bottom duration={1500}>
              <Card
                id="card"
                className="border-0"
                style={{ width: "19em", borderWidth: "0" }}
              >
                <Card.Img variant="top" src={ReactPic} id="cardImage" />
                <Card.Body>
                  <Card.Title id="cardTitle">REACT</Card.Title>
                  <Card.Text id="cardText">
                    I began learning React in order to build this site. Its
                    intuitive and lightweight nature won me over almost
                    instantly. With its reusable components and a wealth of
                    documentation, React has much to offer.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Slide>
            <Slide bottom duration={1500}>
              <Card
                id="card"
                className="border-0"
                style={{ width: "19em", borderWidth: "0" }}
              >
                <Card.Img variant="top" src={JS} id="cardImage" />
                <Card.Body>
                  <Card.Title id="cardTitle">JAVASCRIPT</Card.Title>
                  <Card.Text id="cardText">
                    JavaScript was my first programming language and continues
                    to be the one that I am most comfortable with when it comes
                    to algorithms and data structures.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Slide>
            <Slide bottom duration={1500}>
              <Card
                id="card"
                className="border-0"
                style={{ width: "19em", borderWidth: "0" }}
              >
                <Card.Img variant="top" src={Bootstrap} id="cardImage" />
                <Card.Body>
                  <Card.Title id="cardTitle">BOOTSTRAP</Card.Title>
                  <Card.Text id="cardText">
                    Bootstrap has proven to be an excellent resource when
                    designing mobile-first sites and web applications. It allows
                    me to get a working design going with great styling in
                    little time.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Slide>
            <Slide bottom duration={1500}>
              <Card
                id="card"
                className="border-0"
                style={{ width: "19em", borderWidth: "0" }}
              >
                <Card.Img variant="top" src={Java} id="cardImage" />
                <Card.Body>
                  <Card.Title id="cardTitle">JAVA</Card.Title>
                  <Card.Text id="cardText">
                    With Java I got a crash course in object-oriented
                    programming. I've worked on projects implementing Spring
                    Framework to build stand-alone applications utilizing
                    Thymeleaf dynamic templating.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Slide>
            <Slide bottom duration={1500}>
              <Card
                id="card"
                className="border-0"
                style={{ width: "19em", borderWidth: "0" }}
              >
                <Card.Img variant="top" src={MySql} id="cardImage" />
                <Card.Body>
                  <Card.Title id="cardTitle">MYSQL</Card.Title>
                  <Card.Text id="cardText">
                    MySQL is an open source relational database management
                    system with a client-server model. I've had experience
                    performing operations including querying, data manipulation,
                    and access control.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Slide>
            <Slide bottom duration={1500}>
              <Card
                id="card"
                className="border-0"
                style={{ width: "19em", borderWidth: "0" }}
              >
                <Card.Img variant="top" src={Heroku} id="cardImage" />
                <Card.Body>
                  <Card.Title id="cardTitle">HEROKU</Card.Title>
                  <Card.Text id="cardText">
                    Heroku has quickly become my hosting platform of choice. It
                    makes deploying apps super convenient as its GitHub
                    integration is seamless. Also, Heroku's basic tier is free —
                    a huge plus.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Slide>
            <Slide bottom duration={1500}>
              <Card
                id="card"
                className="border-0"
                style={{ width: "19em", borderWidth: "0" }}
              >
                <Card.Img variant="top" src={Adobe} id="cardImage" />
                <Card.Body>
                  <Card.Title id="cardTitle">ADOBE CC</Card.Title>
                  <Card.Text id="cardText">
                    It's always important to have the right tools for the job
                    and full stack development is no different. Adobe Creative
                    Cloud provides many great products but I particularly like
                    working with Photoshop and Lightroom.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Slide>
          </div>
        </div>
      </div>
    );
  }
}

export default Tech;
